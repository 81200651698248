@use '@/scss/underscore' as _;

.main,
.divider,
.otherMethods {
  margin-bottom: _.unit(4);
}

.terms {
  margin-bottom: _.unit(4);
  text-align: center;
  @include _.text-hint;
  font: var(--font-body-3);
}

.checkbox {
  justify-content: center;
}

.createAccount,
.singleSignOn {
  text-align: center;
  margin-bottom: _.unit(4);
}

.placeHolder {
  flex: 1;
}

:global(body.desktop) {
  .placeHolder {
    flex: 0;
  }
}
