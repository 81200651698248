@use '@/scss/underscore' as _;


.overlay {
  z-index: 101;
}

.modal {
  z-index: 101;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.container {
  background: var(--color-bg-body);
  height: 100%;
  @include _.flex-column;
  align-items: stretch;
  overflow: hidden;
}

.modal,
.container {
  &:focus-visible {
    outline: none;
  }
}

.header {
  padding: _.unit(2) _.unit(5);
}

.content {
  flex: 1;
  width: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
  background: var(--color-bg-body);
  opacity: 0%;
  transition: opacity 0.2s ease-in-out;

  &.loaded {
    opacity: 100%;
  }
}

.loader {
  background: var(--color-brand-default);
}

/* stylelint-disable selector-class-pattern */
:global {
  .ReactModal__Content[id='iframe-modal'] {
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
  }

  .ReactModal__Content--after-open[id='iframe-modal'] {
    transform: translateY(0);
  }

  .ReactModal__Content--before-close[id='iframe-modal'] {
    transform: translateY(100%);
  }
}
/* stylelint-enable selector-class-pattern */
